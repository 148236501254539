import { render, staticRenderFns } from "./newsPart.vue?vue&type=template&id=3ca8de7a&scoped=true&"
import script from "./newsPart.vue?vue&type=script&lang=js&"
export * from "./newsPart.vue?vue&type=script&lang=js&"
import style0 from "./newsPart.vue?vue&type=style&index=0&id=3ca8de7a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ca8de7a",
  null
  
)

export default component.exports