<template>
	<div class="popTopics">
		<div class="part_title flex align_items_c justify_content_sb">
			<span class="title_text">热门专题</span>
			<span class="title_more" @click="toPartPage">查看更多</span>
		</div>
		<div class="part_content flex">
			<div
				class="part_content_item"
				v-for="(item, ind) in partsData"
				:key="ind"
			>
				<router-link
					target="_blank"
					:to="{ name: '/gw_dex', params: { id: item.id } }"
				>
					<span class="item_name">#{{ item.name }}#</span>
				</router-link>
			</div>
		</div>
	</div>
</template>
<script>
import { hotTopicsList } from "@/api/home";
export default {
	props: [""],
	data() {
		return {
			partsData: [],
		};
	},
	created() {
		this.getHotTopicsList();
	},
	methods: {
		toPartPage() {
			this.$router.push({
				name: "/hot_topics",
			});
		},
		// 获取专题
		getHotTopicsList() {
			const that = this;
			hotTopicsList({ size: 99 })
				.then((res) => {
					// sessionStorage.setItem("homeflashList", JSON.stringify(res));
					if (res.code == 200) {
						that.partsData =
							res.data.length >= 8 ? res.data.slice(0, 8) : res.data;
					}
				})
				.catch((error) => {});
		},
	},
};
</script>
<style scoped>
.popTopics {
	background: #1c1c1e;
	padding: 20px 15px 25px;
}
.part_title {
	color: #fff;
	margin-bottom: 15px;
}
.title_text {
	font-size: 18px;
	line-height: 1;
	font-weight: 600;
}
.title_more {
	font-size: 14px;
	line-height: 1;
}
.part_content {
	flex-wrap: wrap;
	row-gap: 15px;
	column-gap: 20px;
}
.part_content_item {
	font-family: PingFang SC;
	letter-spacing: 0px;
	color: #1c1c1e;
}
.part_content_item span {
	font-size: clamp(12px, calc(100vw * 12 / 375), 16px);
	font-weight: 600;
	line-height: 1;
	padding: 5px 8px;
	background: #9f9f9f;
	border-radius: 10px;
}
</style>
