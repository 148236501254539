<template>
	<div class="mobile_home">
		<template>
			<div class="topbgPart">
				<TopSwiper type="1"></TopSwiper>
				<NewsPart></NewsPart>
			</div>
			<!-- 这个是原来的主编推荐，省略掉 -->
			<!-- <EditorCommend :zbtjList="zbtjList"></EditorCommend> -->
		</template>
		<!-- 顶部导航   -->
		<div class="navBox" ref="navBox">
			<div
				class="navItem"
				ref="navItem"
				:class="{ activeItem: activeIndex === index }"
				v-for="(item, index) in categoryLists"
				:key="index + '1'"
				@click="changePart(index, $event)"
			>
				{{
					localLanguage == 2
						? item.name_en
							? item.name_en
							: item.name
						: item.name
				}}
				<img
					v-if="activeIndex === index"
					src="https://res.metaera.hk/resources/assets/images/home/activeTag.png"
				/>
			</div>
		</div>
		<div class="bottom_box">
			<swiper :options="options" ref="mySwiper">
				<swiperSlide
					v-for="(item, index) in categoryLists"
					:key="index + '2'"
					v-show="activeIndex == index"
				>
					<template v-if="index == 0">
						<div
							class="news_box"
							v-for="(hotItem, hotIndex) in hotLists"
							:key="hotIndex + '3'"
						>
							<PerMobileNews
								:item="hotItem"
								v-if="hotIndex < 5"
							></PerMobileNews>
						</div>
						<!-- <PopColumn :popColumnLists="popColumnLists"></PopColumn> -->
						<PopTopics></PopTopics>
					</template>
					<div
						class="news_box"
						v-for="(articleItem, articleIndex) in articleList"
						:key="articleItem.id"
					>
						<PerMobileNews :item="articleItem"></PerMobileNews>
					</div>
				</swiperSlide>
			</swiper>
		</div>
	</div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import TopSwiper from "@/components/topSwiper";
import NewsPart from "../newsPart";
import EditorCommend from "../editorCommend";
import PerNews from "./perNews";
import PerMobileNews from "@/components/mobile/perMobileNews";
import PopColumn from "./popColumn";
import PopTopics from "./popTopics";
import { getArticleList } from "@/api/home";

import { newBus } from "@/components/pc/topHeader2.vue";

export default {
	props: [
		"categoryLists",
		"zbtjList",
		"popColumnLists",
		"popColumnLists",
		"hotLists",
	],
	components: {
		TopSwiper,
		NewsPart,
		EditorCommend,
		PerNews,
		PopColumn,
		swiper,
		swiperSlide,
		PopTopics,
		PerMobileNews,
	},
	data() {
		return {
			localLanguage: 1,
			activeIndex: 0,
			boxWidth: 0,
			articleList: [],
			flag: false,
			options: {
				loop: false,
				autoplay: false,
				autoplayDisableOnInteraction: false,
				direction: "horizontal",
				grabCursor: true,
				setWrapperSize: true,
				autoHeight: true,
				initialSlide: 0,
				onSlideChangeEnd: (swiper) => {
					// eslint-disable-line no-shadow
					// 使用es6的箭头函数，使this指向vue对象
					// console.log("--------", swiper.activeIndex);
					this.changePart(swiper.activeIndex);
				},
			},
		};
	},
	created() {
		this.getArticleList({
			category_id: 0,
			type: 1,
			type1: 7,
			page: this.page,
			size: this.size,
		});
	},
	mounted() {
		this.boxWidth = this.$refs.navBox.clientWidth / 2;

		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		changePart(index, e) {
			this.activeIndex = index;
			this.changeItem(index);
			this.flag = true;
			// const offsetLeft = this.$refs.navItem[index].offsetLeft;
			// const offsetWidth = this.$refs.navItem[index].offsetWidth / 2;
			// this.$refs.navBox.scrollLeft = offsetLeft + offsetWidth - this.boxWidth;
			if (e) {
				this.$refs.mySwiper.swiper.slideTo(index);
			}
		},
		changeItem(index) {
			this.articleList = [];
			this.activeIndex = index;
			const offsetLeft = this.$refs.navItem[index].offsetLeft;
			const offsetWidth = this.$refs.navItem[index].offsetWidth / 2;
			this.$refs.navBox.scrollLeft = offsetLeft + offsetWidth - this.boxWidth;
			this.getArticleList({
				category_id: this.categoryLists[index].id,
				type: 1,
				page: this.page,
				size: this.size,
			});
		},
		getArticleList(obj, type) {
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.articleList = res.data.list;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.mobile_home {
	background: #121212;
}
.topbgPart {
	padding: 15px calc(100vw * 15 / 375);
	background: #1c1c1e;
}
.navBox {
	padding-left: calc(100vw * 9 / 375);
	display: flex;
	overflow: scroll;
	scroll-behavior: smooth;
	scroll-duration: 3s;
	background: #121212;
	border-radius: 30px 30px 0 0;
}
.navBox::-webkit-scrollbar {
	width: 0;
	display: none;
}
.navItem {
	height: 60px;
	box-sizing: border-box;
	padding: 20px 10px;
	flex-shrink: 0;
	line-height: 22px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #a0a0a9;
}
.activeItem {
	padding: 18px 6px 4px;
	line-height: 28px;
	font-size: 20px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
.activeItem img {
	display: block;
	height: 7px;
	margin: 0 auto;
}
.news_box {
	width: 100%;
	box-sizing: border-box;
	padding: 0 15px;
}
</style>
<style>
.swiper-wrapper {
	height: auto !important;
}
.swiper-slide {
	display: block;
	padding: 0;
}
</style>
